export const SESSION_FOR_POLL_AND_QN_A_FRAGMENT = `
  fragment sessionForPollAndQnAFragment on Session {
    uid
    id
    schemaCode
    name
    showPolls
    showQNA
    parentSession {
      uid
    }
  }
`;
